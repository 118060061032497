/*
	Name:				Core.js
	Description:        Core script for the theme.
	Version:            1.0.0
	Author:             Garrison Hughes
*/
jQuery(document).ready(function ($) {
    function expandCollapseMenu(evt) {
        //If the menu isn't visible, show it
        if ($(this).hasClass('open-menu')) {
            $(this).removeClass('open-menu').addClass('close-menu');
            $('.main_menu_container').removeClass('hide').addClass('show');
        } else {
            $(this).removeClass('close-menu').addClass('open-menu');
            $('.main_menu_container').removeClass('show').addClass('hide');
        }
    }

    function expandCollapseMenuItem(evt) {
        var $span = $(evt.currentTarget);
        var $item = $(evt.currentTarget).next('ul.sub-menu');

        if ($span.hasClass('show')) {
            $span.removeClass('show').addClass('close');
            $item.removeClass('hide').addClass('show');
        } else {
            $span.addClass('show').removeClass('close');
            $item.removeClass('show').addClass('hide');
        }
    }

    var mobileMenuIcon = function () {
        var screenWidth = document.body.clientWidth;
        if (screenWidth < 978) {
            $('.menu-item-has-children').addClass('disable-hover');
            $('.menu-items-btn').addClass('show');
            $('.menu-items-btn').removeClass('hide');
            $('.menuBtn').removeClass('hide');
            $('.searchIcon').removeClass('activate');
        } else if (screenWidth >= 978) {
            $('.menu-item-has-children').removeClass('disable-hover');
            $('.menu-items-btn').addClass('hide');
            $('.menu-items-btn').removeClass('show close');
            $('.menuBtn').addClass('hide');

            if ($('.menuBtn').hasClass('close-menu')) {
                $('.menuBtn').removeClass('close-menu');
                $('.menuBtn').addClass('open-menu');
            }

            $('.main_menu_container').removeClass('show');
            $('.main_menu_container').removeClass('hide');

            if ($('ul.sub-menu').hasClass('show')) {
                $('ul.sub-menu').removeClass('show');
            }
            $('.searchIcon').addClass('activate');
        }
    };

    // reveal inverse navbar on scroll
    function revealScrollNav() {
        if (document.body.scrollTop > 120 || document.documentElement.scrollTop > 120) {
            document.getElementById('mainNavigation_scroll').style.top = '0';
            $('#mainNavigation').addClass('shadow');
        } else {
            document.getElementById('mainNavigation_scroll').style.top = '-120px';
            $('#mainNavigation').removeClass('shadow');
        }
    }

    function displayDesktopSearch() {
        if ($('.searchIcon').hasClass('activate')) {
            $('#search-overlay').css('display', 'block');
            $('body').css('overflow', 'hidden');
        }
        return;
    }

    function hideDesktopSearch() {
        $('#search-overlay').css('display', 'none');
        $('body').css('overflow', 'visible');
    }

    // Check for Simple Banner for COVID Alert
    // function checkForSimpleBanner() {
    //     //if it exist
    //     if ($('div.simple-banner')) {
    //         //add class to main tag to allow proper margin styling
    //         $('main#main').addClass('alert_banner_present');
    //     }
    //     return;
    // }
    // checkForSimpleBanner();
    //*** Slick Stuff */

    // Masthead Slider
    if ($('.masthead-image-slider').length > 0) {
        $('.masthead-image-slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 10000,
            speed: 1000,
            fade: true,
            cssEase: 'ease',
            draggable: false,
            dots: false,
            arrows: false,
        });
    }

    // Organizations Slider
    //only run this if the slider element exists
    if ($('.organization_slider .items').length > 0) {
        $('.organization_slider .items').slick({
            touchMove: false,
            arrows: false,
            draggable: false,
            infinite: true,
            pauseOnHover: false,
            speed: 5000,
            autoplay: true,
            autoplaySpeed: 0,
            cssEase: 'linear',
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            variableWidth: true,
        });
    }

    // Image Block - Vertical Slider
    if ($('.image_slider .image_figure').length > 0) {
        $('.image_slider').slick({
            infinite: true,
            autoplay: false,
            autoplaySpeed: 5000,
            speed: 1000,
            fade: true,
            cssEase: 'ease',
            draggable: false,
            dots: true,
            arrows: false,
            appendDots: $('.slick-dots-slider'),
        });
    }
    $('.prev').click(function () {
        $('.image_slider').slick('slickPrev');
    });

    $('.next').click(function () {
        $('.image_slider').slick('slickNext');
    });

    // Gallery Block - Large Slider
    if ($('.gallery_images .gallery_image').length > 0) {
        $('.gallery_images').each(function (index) {
            $(this).attr('data-slider', index);
            $(this).addClass('slider_' + index);
            $(this).slick({
                appendDots: $('.slick_gallery_slider')[index],
                appendArrows: $('.arrow_container')[index],
                centerMode: true,
                centerPadding: '60px',
                slidesToShow: 1,
                variableWidth: true,
                infinite: true,
                dots: true,
                arrows: true,
                adaptiveHeight: true,
            });
        });

        $('.prev_gallery').click(function () {
            $('.gallery_images').slick('slickPrev');
        });

        $('.next_gallery').click(function () {
            $('.gallery_images').slick('slickNext');
        });
    }

    // Playroom Modal(s)
    $('.modal_link').click(function (evt) {
        var modalID = $(evt.currentTarget).attr('data-playroom-id');
        console.log(modalID);
        $('#' + modalID).addClass('show');
    });

    $('.modal_close i').click(function () {
        $('.modal_component').removeClass('show');
    });

    //GOOGLE MAP CALL Playroom Locations
    //GOOGLE MAP CALL For Sidebar(s)
    if ($('.map').length > 0) {
        var s = document.createElement('script');
        s.type = 'text/javascript';
        s.setAttribute('async', '');
        s.setAttribute('defer', '');
        s.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCzdRb4VvR5qYB8_naW4HbOOJso763B-8Y&callback=initializeMaps&loading=async';
        // Use any selector
        $('body').append(s);
    }

    $('.menu-items-btn').on('click tap', expandCollapseMenuItem);
    $('#mobileMenuButton').on('click tap', expandCollapseMenu);
    $('.searchIcon button').on('click tap', displayDesktopSearch);
    $('.search-text i').on('click tap', hideDesktopSearch);

    $(window).resize(function () {
        mobileMenuIcon();
    });

    $(window).scroll(function () {
        revealScrollNav();
    });
    mobileMenuIcon();
    //AOS.JS
    AOS.init({
        duration: 800,
        delay: 300, // values from 0 to 3000, with step 50ms
        animatedClassName: 'future_milestone_wrapper', // class applied on animation
    });
});

//----------------------------------------------------------------------------------------------------------------------------
/**
 * SimpleBanner - Covid  Banner
 *
 * Handles responsive and desktop cases for the display of the SimpleBanner plugin
 *
 * @date    23/11/20
 * @param   jQuery $el The jQuery element.
 */
jQuery(document).ready(function ($) {
    //check for plugin instance
    if ($('#simple-banner').length > 0) {
        //resize handler
        $(window).resize(function () {
            handleSimpleBannerMobile($);
        });
        //initial function run
        handleSimpleBannerMobile($);
    }
});
//handle resize events for SimpleBanner
function handleSimpleBannerMobile($) {
    //only perform this function on mobile screen sizes
    if ($('body').width() < 980) {
        //check if we've ever cloned the banner before - if not clone it and name it x-mobile
        if ($('#simple-banner-mobile').length == 0) {
            //clone string
            var sbhtml = '<div id="simple-banner-mobile" class="simple-banner">' + $('#simple-banner').html() + '</div>';
            //add to main navigation container
            $('#mainNavigation').prepend(sbhtml);
        }
        //adjust margin on the main ID element based on the banner heidht and navigation height
        $('#main').css('margin-top', $($('#mainNavigation').find('.content')[0]).height() + $('#simple-banner-mobile').height());
    } else {
        //desktop sizes - remove inline styles written while at mobile sizes
        if ($('#main').attr('style').length > 0) {
            $('#main').attr('style', '');
        }
    }
}
//----------------------------------------------------------------------------------------------------------------------------

function initializeMaps() {
    //RENDER MAPS
    jQuery('.map').each(function () {
        var map = initMap(jQuery(this));
    });
}
/**
 * initMap
 *
 * Renders a Google Map onto the selected jQuery element
 *
 * @date    22/10/19
 * @since   5.8.6
 *
 * @param   jQuery $el The jQuery element.
 * @return  object The map instance.
 */
function initMap($el) {
    // Find marker elements within map.
    var $markers = $el.find('.marker');

    // Create gerenic map.
    var mapArgs = {
        zoom: $el.data('zoom') || 16,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        streetViewControl: false,
        scaleControl: false,
        zoomControl: false,
        draggable: false,
        disableDefaultUI: false,
        fullscreenControl: false,
        keyboardShortcuts: false,
        mapTypeControl: false,
        panControl: false,
        rotateControl: false,
        scrollwheel: false,
        disableDoubleClickZoom: true,
        clickableIcons: false,
        backgroundColor: '#fff',
    };
    var map = new google.maps.Map($el[0], mapArgs);

    // Add markers.
    map.markers = [];
    $markers.each(function () {
        initMarker(jQuery(this), map);
    });

    // Center map based on markers.
    centerMap(map);

    // Return map instance.
    return map;
}

/**
 * initMarker
 *
 * Creates a marker for the given jQuery element and map.
 *
 * @date    22/10/19
 * @since   5.8.6
 *
 * @param   jQuery $el The jQuery element.
 * @param   object The map instance.
 * @return  object The marker instance.
 */
function initMarker($marker, map) {
    // Get position from marker.
    var lat = $marker.data('lat');
    var lng = $marker.data('lng');
    var latLng = {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
    };

    // Create marker instance.
    var marker = new google.maps.Marker({
        position: latLng,
        map: map,
        icon: themeURL + '/dist/static/images/map_marker.svg',
    });

    // Append to reference for later use.
    map.markers.push(marker);

    // If marker contains HTML, add it to an infoWindow.
    if ($marker.html()) {
        // Create info window.
        var infowindow = new google.maps.InfoWindow({
            content: $marker.html(),
        });

        // Show info window when marker is clicked.
        google.maps.event.addListener(marker, 'click', function () {
            infowindow.open(map, marker);
        });
    }
}

/**
 * centerMap
 *
 * Centers the map showing all markers in view.
 *
 * @date    22/10/19
 * @since   5.8.6
 *
 * @param   object The map instance.
 * @return  void
 */
function centerMap(map) {
    // Create map boundaries from all map markers.
    var bounds = new google.maps.LatLngBounds();
    map.markers.forEach(function (marker) {
        bounds.extend({
            lat: marker.position.lat(),
            lng: marker.position.lng(),
        });
    });

    // Case: Single marker.
    if (map.markers.length == 1) {
        map.setCenter(bounds.getCenter());

        // Case: Multiple markers.
    } else {
        map.fitBounds(bounds);
    }
}

// YOUTUBE PLAYER
// var iframe = document.querySelector('.media_block .embed-container');
// iframe.setAttribute('id', videoId);
// var videoId = iframe.id;
